export const environment = {
  production: false,
  site: 'l83',
  translationKey: 'mintjl',
  clientDetails: {
    name: 'Låneforeningen af 1883',
    address1: 'Gammel Kongevej 60, 10. sal',
    zipCode: '1850',
    city: 'Frederiksberg C.',
    country: 'Danmark',
    phone: '+45 3616 1675',
  },
  formSubmitEmail: 'mho@sitemule.com;crl@sitemule.com(cc)',
  logoPath: '/assets/images/l83-logotopbar.png',
  logoFooterPath: '/assets/images/l83-logofooter.png',
  homepage: 'https://www.1883.dk',
  emailTemplateConfig: {
    logoLink: 'https://www.1883.dk/Files/Images/1883%202020/Images/Graphics/Laaneforeningen-af-1883.png',
    conditionLink: 'https://www.1883.dk/laanebetingelser',
    primaryColor: '#83b531'
  },
  sentryDSN: 'https://e4121076ac554c4bbdaea244e28adb9f@o263138.ingest.sentry.io/4504610673459200'
};
